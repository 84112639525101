<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-overlay
      :show="$store.state.reportStore.ticketLoading"
      rounded="sm"
      variant="transparent"
      opacity="0.5"
      blur="2px"
    >
      <b-table
        ref="refInvoiceListTable"
        :items="fetchTickets"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Column: Balance -->
        <template #cell(sent_for)="data">
          <div>
            {{ getMonthDate(data.item.sent_for) }}
          </div>
        </template>

        <template #cell(created_at)="data">
          <div>
            {{ getDate(data.item.created_at) }}
          </div>
        </template>

        <!-- Column: Balance -->
        <template #cell(url)="data">
          <div class="">
            <b-badge
              variant="primary"
              pill
              v-b-tooltip.hover
              title="View Report"
              class="badge-round cursor-pointer mb-50 mr-50"
              @click="
                downloadReport({
                  url: data.item.url,
                  date: data.item.sent_for
                })
              "
            >
              <feather-icon
                :id="`matter-${data.item._id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mr-25"
              />
              <span>View</span>
            </b-badge>
          </div>
        </template>
      </b-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BModal,
  BCardText,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip,
  VBModal
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'

// import store from '@/store'
import useReports from './useReports'

export default {
  components: {
    BCard,
    BRow,
    BModal,
    BCardText,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BListGroup,
    BListGroupItem,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BOverlay,
    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple
  },
  methods: {
    getDate(date) {
      return moment(date).format('lll')
    },
    getMonthDate(date) {
      return moment(date).format('MMMM, YYYY');

    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    downloadReport({ url, date }) {
      this.downloadAs(url, `Report-${date}.pdf`)
    }
  },

  setup(props) {
    let userData = JSON.parse(localStorage.getItem('userData'))

    const {
      fetchTickets,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData
    } = useReports()

    return {
      userData,
      fetchTickets,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>
