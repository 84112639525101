import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    ticketLoading: false
  },
  getters: {},
  mutations: {
    changeTicketLoading(state, val) {
      state.ticketLoading = val
    }
  },
  actions: {
    fetchTickets({ commit }, { ctx }) {
      commit('changeTicketLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getReportsEndPoint)
          .then((response) => {
            commit('changeTicketLoading', false)
            // console.log({
            //   FetchingMatterData: JSON.stringify(response.data.meta.total)
            // })
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeTicketLoading', false)
            reject(error)
          })
      })
    }
  }
}
