import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import matterStore from './dashboard/matter-store'
import hearingStore from './dashboard/hearing-store'
import displayboardStore from './dashboard/displayboard-store'
import courtStore from './dashboard/court-store'
import settingStore from './dashboard/setting-store'
import causelistStore from './dashboard/causelist-store'
import notificationStore from './dashboard/notification-store'
import resetPasswordStore from './reset-password/reset-password-store'
import lawtrendStore from './dashboard/lawtrend-store'
import ticketStore from './dashboard/ticket-store'
import invoiceStore from './dashboard/invoice-store'
import firTrackerStore from './dashboard/fir-tracker-store'
import sciTrackerStore from './dashboard/sci-tracker-store'
import upCaveatStore from './dashboard/up-caveat-store'
import taskStore from './dashboard/task-store'
import reportStore from './dashboard/report-store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    matterStore,
    hearingStore,
    causelistStore,
    firTrackerStore,
    sciTrackerStore,
    upCaveatStore,
    displayboardStore,
    notificationStore,
    courtStore,
    settingStore,
    resetPasswordStore,
    lawtrendStore,
    ticketStore,
    invoiceStore,
    taskStore,
    reportStore
  },
  strict: process.env.DEV,
})
