var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('basic-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'case_details'
      )
    )?_c('div',[_c('case-status',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter,"detail":_vm.$store.state.matterStore.currentmatter.case_details}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes('category')
    )?_c('div',[_c('category-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'linked_matters'
      )
    )?_c('div',[(_vm.$store.state.matterStore.currentmatter.linked_matters)?_c('linked-matters',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}}):_vm._e()],1):_vm._e(),_c('div',[_c('petitioner-respondents',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1),(
      Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
        'listing_history'
      )
    )?_c('div',[_c('listing-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1):_vm._e(),_c('b-card',{attrs:{"no-body":""}},[_c('app-collapse',{attrs:{"accordion":""}},[(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'notices'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Notices"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.notices,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'tagged_matters'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Tagged Matters"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.tagged_matters,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'listing_dates'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Listing Dates"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.listing_dates,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'drop_note'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Drop Notes"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.drop_note,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'other_documents'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Other Documents"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.other_documents,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'court_fees'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Court Fees"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.court_fees,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'mention_memo'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Mention Memo"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.mention_memo,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'caveat'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Caveat"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.caveat,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'similaraties'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Similaraties"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.similaraties,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'defects'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Defects"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.defects,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
            'earlier_court_details'
          )
        )?_c('app-collapse-item',{attrs:{"title":"Earlier Court Details"}},[_c('DynamicListTable',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.earlier_court_details,"title":""}})],1):_vm._e(),(
          Object.keys(_vm.$store.state.matterStore.currentmatter).includes('ia')
        )?_c('app-collapse-item',{attrs:{"title":"IA Details"}},[_c('CollapseListItem',{attrs:{"data":_vm.$store.state.matterStore.currentmatter.ia}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }