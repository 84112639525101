import { getUserData } from '@/auth/utils'
import moment from 'moment'

export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read'
    }
  },
  {
    path: '/tasks/calendar',
    name: 'task-calendar',
    component: () => import('@/views/tasks/calendar/Calendar.vue'),
    meta: {
      pageTitle: 'Tasks',
      breadcrumb: [
        {
          text: 'Tasks'
        },
        {
          text: 'Calendar',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/others/reports',
    name: 'reports',
    component: () => import('@/views/others/reports/Reports.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Report List',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/others/court-links',
    name: 'court-links',
    component: () => import('@/views/others/court-links/CourtLinks.vue'),
    meta: {
      pageTitle: 'Court Links',
      breadcrumb: [
        {
          text: 'Others'
        },
        {
          text: 'Court Links',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/others/invoices',
    name: 'invoice-list',
    component: () => import('@/views/others/invoices/InvoiceScreen.vue'),
    meta: {
      pageTitle: 'Invoices',
      breadcrumb: [
        {
          text: 'Invoice'
        },
        {
          text: 'List',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/others/invoices/search-matter',
    name: 'invoice-matter',
    component: () =>
      import('@/views/others/invoices/search-matter/SearchMatter.vue'),
    meta: {
      pageTitle: 'Matters',
      breadcrumb: [
        {
          text: 'Select Matter',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/others/invoices/invoice-add/:type/:id',
    name: 'invoice-add',
    component: () =>
      import('@/views/others/invoices/invoice-add/InvoiceAdd.vue'),
    meta: {
      pageTitle: 'Invoices',
      breadcrumb: [
        {
          text: 'Invoice'
        },
        {
          text: 'Create',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/others/invoices/invoice-add-blank',
    name: 'invoice-add-blank',
    component: () =>
      import('@/views/others/invoices/invoice-add/InvoiceAddBlank.vue'),
    meta: {
      pageTitle: 'Invoices',
      breadcrumb: [
        {
          text: 'Invoice'
        },
        {
          text: 'Create',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  }
]
