import moment from 'moment'
export default {
  // End Points ==============

  baseUrl: 'https://app.mymunshi.in',
  // Get All Courts
  allCourtEndPoint: '/api/courts/all',
  getCaseTypesEndPoint({ id }) {
    return '/api/model/high_courts?filters[_id]=' + id
  },
  userCourtEndPoint: '/api/model/matters?group=hc_name',
  // Update User
  userUpdate: '/api/users/update',

  getUser: '/api/me',

  sendOTP: '/api/reset/request_otp',

  verifyOTP: '/api/reset/verify',

  resetPassword: '/api/reset/change',

  // Matters
  getMattersEndPoint: '/api/model/matters',
  getMattersNewEndPoint: '/api/matter_list/matters',
  // Get Matter By Id
  getMatterEndPoint({ id }) {
    return '/api/matters/' + id
  },

  updateMatterPublicDataEndPoint({ id }) {
    return '/api/matters/public_data/' + id
  },

  updateMatterTitleEndPoint({ id }) {
    return '/api/matters/title/' + id
  },

  updateMatterHearingDateEndPoint({ id }) {
    return '/api/matters/ec/' + id
  },

  // Get Matter Judgments
  getJudgmentEndPoint: '/api/matters/judgements',
  allJudgmentExportEndPoint({ id }) {
    return '/api/orders/exports?matter_id=' + id
  },

  refreshJudgmentEndPoint: '/api/hc-allahabad/sync-order',

  // Find Matter
  // findMatterEndPoint({ unique_id, api }) {
  //   const apiPathMap = new Map([
  //     ['hc-lucknow', '/api/courts/find'],
  //     ['hc-allahabad', '/api/courts/find'],
  //     ['tribunal-ncdrc', '/api/tribunal-ncdrc/find'],
  //     ['tribunal', '/api/tribunals/find']
  //   ])

  //   for (let [key, value] of apiPathMap) {
  //     if (unique_id.includes(key)) {
  //       return value
  //     }
  //   }
  //   return `/api/${unique_id}/find`
  // },

  findMatterEndPoint({ unique_id, api }) {
    if (['hc-lucknow', 'hc-allahabad'].includes(unique_id)) {
      return '/api/courts/find'
    }
    return `/api/${unique_id}/find`
  },

  // findTribunalsMatterEndPoint: '/api/tribunals/find',
  // Save Matter
  saveMatterEndPoint: '/api/matters',

  saveOtherMatterEndPoint: '/api/matters/other/create',

  updateOtherMatterEndPoint({ id }) {
    return '/api/matters/other/' + id
  },

  exportAllMattersEndPoint({ court_id, type, range }) {
    let endpoint =
      '/api/model/matters?fields=title,case_status,reference_id,hc_name,unique_id&export=true&limit=100000'
    if (type) {
      endpoint += '&type=' + type
    }
    if (court_id) {
      endpoint += '&filters[court_id]=' + court_id
    }
    if (range) {
      endpoint += '&range=' + range
    }
    return endpoint
  },

  // Find District Matter
  findDistrictMatterEndPoint: '/api/ecourts/find',

  // Save District Matter
  saveDistrictMatterEndPoint: '/api/matters',

  // Save CGIT Matter
  saveCGITMatterEndPoint: '/api/add',

  // Delete Matter
  deleteMatterEndPoint({ id }) {
    return '/api/matters/delete/' + id
  },

  findMatterByCNREndPoint: '/api/ecourts/find',

  getTrackerCount({ type }) {
    return '/api/model/matters?group=' + type
  },

  // Get Matter Notes
  getMatterNotesEndPoint({ id }) {
    return '/api/matters/list_notes/' + id
  },
  // Save Matter Note
  saveMatterNoteEndPoint: '/api/matters/create_notes',
  // Update Matter Note
  updateMatterNoteEndPoint: '/api/matters/update_notes',
  // Delete Matter Note
  deleteMatterNoteEndPoint({ id }) {
    return '/api/matters/delete_notes/' + id
  },

  updateImage: '/api/users/upload_pic',

  // Get Matter Docs
  getMatterDocsEndPoint({ id }) {
    return '/api/matters/list_document/' + id
  },
  // Save Matter Doc
  saveMatterDocEndPoint: '/api/matters/create_document',
  // Delete Matter Doc
  deleteMatterDocEndPoint({ id }) {
    return '/api/matters/delete_document/' + id
  },

  // Get Causelist
  getHearingEndPoint: '/api/hearing',

  hearingDownloadEndPoint: '/api/hearing/filter',

  hearingExportByDateEndPoint({ date }) {
    return '/api/hearing/exports?type=custom&date=' + date
  },

  entireExportByDateEndPoint({ date }) {
    return '/api/hearing/exports?type=entire&date=' + date
  },

  // Get Archived Causelist
  getArchivedCauselistEndPoint({ perPage = 15, page = 1 }) {
    var startdate = moment()
    startdate = startdate.subtract(30, 'days')
    startdate = startdate.format('YYYY-MM-DD')

    var enddate = moment()

    var hours = moment().local().hour()

    if (hours < 18) {
      enddate = enddate.subtract(1, 'days')
    }

    enddate = enddate.format('YYYY-MM-DD')

    console.log('Dates', startdate, enddate)

    // return (
    //   '/api/model/causelist_history?from=' +
    //   startdate +
    //   '&to=' +
    //   enddate +
    //   '&time=datetime&sort=date&order=-1&perPage=' +
    //   perPage +
    //   '&page=' +
    //   page
    // )

    return (
      '/api/archive/?from=' +
      startdate +
      '&to=' +
      enddate +
      '&order=-1&perPage=' +
      perPage +
      '&page=' +
      page
    )
  },

  // Get Display Board
  getDisplayBoardEndPoint({ id }) {
    return '/api/display_board/' + id
  },

  getReportsEndPoint: '/api/list_report',

  getTicketsEndPoint: '/api/model/support_tickets',

  saveTicketEndPoint: '/api/support/ticket',

  getNotificationEndPoint: '/api/model/notification_logs',

  readAllNotificationEndPoint: '/api/notification',

  readNotificationEndPoint(id) {
    return '/api/notification/' + id
  },

  getInvoiceEndPoint: '/api/model/invoice',

  saveInvoiceEndPoint: '/api/invoice',

  deleteInvoiceEndPoint(_id) {
    console.log('DDD', _id)
    return '/api/invoice/' + _id
  },

  getFIRDistrictEndPoint(uniqie_id) {
    return (
      '/api/model/fir_districts?filters[reference_id]=' +
      uniqie_id +
      '&limit=1000'
    )
  },

  deleteFIREndPoint(_id) {
    return '/api/fir_matters/delete/' + _id
  },

  getFIRTrackerEndPoint: '/api/model/case_tracker_matters',

  saveFIREndPoint: '/api/crime/find',

  // Get Matter By Id
  getFIRCaseEndPoint({ id }) {
    return '/api/fir_matters/' + id
  },

  addFIRToMatterEndPoint: '/api/fir_matters',

  // SCI Tracker
  getSCTrackerCourtsEndPoint: '/api/sci/get_courts',

  getSCTrackerStatesEndPoint: '/api/sci/get_states',

  getSCTrackerBenches(state_id, court_id) {
    return '/api/sci/get_bench?state_id=' + state_id + '&court_id=' + court_id
  },

  saveSCTrackerEndPoint: '/api/sci/appeal',

  deleteSCTrackerEndPoint(_id) {
    return '/api/sci/appeal?id=' + _id
  },

  getSCTrackersEndPoint: '/api/model/supreme_court_appeal_tracker',

  // UP Caveat
  getUPCaveatListEndPoint: '/api/model/caveat_tracker_matters',

  getUPDistrictEndPoint: '/api/caveat_tracker/up_districts',

  addNewUPCaveatEndPoint: '/api/caveat/find',

  getUPCaveatItemByIDEndPoint(id) {
    return '/api/caveat_tracker/' + id
  },

  deleteUPCaveatItemEndPoint(_id) {
    return '/api/caveat_tracker/delete/' + _id
  },

  addToMatterUPCaveatItemEndPoint: '/api/caveat_tracker',

  getLawTrendNewsEndPoint: 'https://lawtrend.in/wp-json/wp/v2/posts',

  getAppLawTrendNewsEndPoint: 'https://app.lawtrend.in/test.php',

  // Tasks
  addTaskEndPoint: '/api/tasks/',

  updateTaskEndPoint(_id) {
    return '/api/tasks/' + _id
  },

  getTaskListEndPoint: '/api/model/tasks_collection',

  // getTaskListEndPoint({start_date, end_date, calendars}) {
  //   return (
  //     '/api/model/tasks_collection?start_date='+ start_date + '&end_date='+end_date+'&calendars=' + calendars
  //   )
  // },

  getTaskByIdEndPoint(_id) {
    return '/api/tasks/' + _id
  },

  getTaskByDateEndPoint(date) {
    return '/api/tasks/by-date?date=' + date
  },

  getTaskByMonthYearEndPoint(month, year) {
    return '/api/tasks/by-month?month=' + month + '&year=' + year
  },

  deleteTaskEndPoint(_id) {
    return '/api/tasks/' + _id
  },

  getPlansEndPoint: '/api/user_plans',
  generatePaymentEndPoint: '/api/payment',

  // Local Key Names
  allCourtsKeyName: 'allcourts',
  userCourtsKeyName: 'usercourts',
  displayBoardKeyName: 'displayBoard'
}
