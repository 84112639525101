import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { race } from 'rxjs'

export default {
  namespaced: true,
  state: {
    // mattersData: null,
    activeMatters: [],
    closedMatters: [],
    allMatters: [],
    judgmentList: [],
    notesList: [],
    docsList: [],
    notesLoading: false,
    judgmentLoading: false,
    docsLoading: false,
    newMatterLoading: false,
    mattersLoading: false,
    matterPreviewLoading: false,
    matterRefreshLoading: false,
    currentmatter: null,
    trackerCount: null,
    trackerCountYearly: null,
    exportJudgmentLoading: false,
    refreshJudgmentLoading: false,
    clientDetailLoading: false
  },
  getters: {},
  mutations: {
    clearCurrentMatter(state) {
      state.currentmatter = null
    },
    setTrackerCountData(state, payload) {
      state.trackerCount = payload.data
    },
    setTrackerCountYearlyData(state, payload) {
      state.trackerCountYearly = payload.data
    },
    setCurrentMatterData(state, payload) {
      state.currentmatter = payload.data
    },
    changeClientLoading(state, val) {
      state.clientDetailLoading = val
    },
    changeMatterPreviewLoading(state, val) {
      state.matterPreviewLoading = val
    },
    changeMatterRefreshLoading(state, val) {
      state.matterRefreshLoading = val
    },
    setJudgmentData(state, payload) {
      if (payload.success) {
        state.judgmentList = payload.data
      }
      console.log({ JudgmentList: JSON.stringify(state.judgmentList) })
    },
    changeJudgmentLoading(state, val) {
      state.judgmentLoading = val
    },
    changeExportJudgmentLoading(state, val) {
      state.exportJudgmentLoading = val
    },
    changeRefreshJudgmentLoading(state, val) {
      state.refreshJudgmentLoading = val
    },
    clearJudgmentData(state) {
      state.judgmentList = []
    },
    changeMattersLoading(state, val) {
      state.mattersLoading = val
    },
    changeNewMatterLoading(state, val) {
      state.newMatterLoading = val
    },
    changeNotesLoading(state, val) {
      state.notesLoading = val
    },
    setNotesData(state, payload) {
      if (payload.success) {
        state.notesList = payload.data.reverse()
      }
      console.log({ NotesList: JSON.stringify(state.notesList) })
    },
    clearNotesData(state) {
      state.notesList = []
    },
    setDocsData(state, payload) {
      if (payload.success) {
        state.docsList = payload.data.reverse()
      }
      console.log({ DocsList: JSON.stringify(state.docsList) })
    },
    changeDocsLoading(state, val) {
      state.docsLoading = val
    },
    clearDocsData(state) {
      state.docsList = []
    }
  },
  actions: {
    // Matters ------------------------------->>>>>>>>>

    // Get Matter
    fetchMatters({ commit }, { ctx, queryParams }) {
      console.log('Setch Matters -->', queryParams)
      commit('changeMattersLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getMattersNewEndPoint, { params: queryParams })
          .then((response) => {
            commit('changeMattersLoading', false)
            console.log({
              FetchingMatterData: JSON.stringify(response.data.meta.total)
            })
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeMattersLoading', false)
            reject(error)
          })
      })
    },
    // Get Matter
    fetchMatter({ commit }, { ctx, id }) {
      console.log('Matter Id -->', id)
      commit('clearCurrentMatter')
      commit('changeMatterPreviewLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getMatterEndPoint({ id: id }))
          .then((response) => {
            commit('changeMatterPreviewLoading', false)
            console.log({
              'Matter-->': JSON.stringify(response.data)
            })
            if (response.data.success) {
              commit('setCurrentMatterData', response.data)
            }
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeMatterPreviewLoading', false)
            reject(error)
          })
      })
    },
    // Find Matters
    findMatter({ commit }, { ctx, payload, type }) {
      console.log('Matter Find : ', type)
      commit('changeNewMatterLoading', true)
      var url = defaultConfig.findMatterEndPoint({ unique_id: type })
      // if (type.includes('tribunal-')) {
      //   url = defaultConfig.findTribunalsMatterEndPoint
      // }
      return new Promise((resolve, reject) => {
        axiosIns
          .post(url, payload)
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              FetchingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                if (type == 'tribunals-cgrf-haryana') {
                  ctx.$bvModal.hide('modal-create-matter')
                  ctx.$swal({
                    title: 'Saved successfully',
                    icon: 'success',
                    text: 'Case has been successfully added and orders syncing is in progress.\nPlease check the orders after 30 minutes.',
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonText: 'Ok',
                    customClass: {
                      confirmButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                  })
                }
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    'Court server not responding, Please try after some time!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Court server not responding, Please try after some time!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Save Matters
    saveMatter({ commit }, { ctx, payload }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .put(defaultConfig.saveMatterEndPoint, payload)
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$bvModal.hide('modal-create-matter')
                ctx.$swal({
                  title: 'Saved successfully',
                  icon: 'success',
                  text: 'Case has been successfully added and orders syncing is in progress.\nPlease check the orders after 30 minutes.',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-danger'
                  },
                  buttonsStyling: false
                })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Save Matters
    saveCGITMatter({ commit }, { ctx, payload }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.saveCGITMatterEndPoint, payload)
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$bvModal.hide('modal-create-matter')
                ctx.$swal({
                  title: 'Success',
                  icon: 'success',
                  text: 'Case has been successfully into you matter list',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-danger'
                  },
                  buttonsStyling: false
                })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    saveOtherMatter({ commit }, { ctx, payload }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.saveOtherMatterEndPoint, payload)
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Matter saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                ctx.$bvModal.hide('modal-create-matter')
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    updateOtherMatter({ commit }, { ctx, id, payload }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.updateOtherMatterEndPoint({ id: id }), payload)
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Matter updated successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                if (response.data.success) {
                  commit('setCurrentMatterData', response.data)
                }
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Find Matters
    findDistrictMatter({ commit }, { ctx, payload }) {
      console.log('District Matter Payload : ', payload)
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.findDistrictMatterEndPoint, payload)
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              FetchingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    'Court server not responding, Please try after some time!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Court server not responding, Please try after some time!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    saveDistrictMatter({ commit }, { ctx, payload, isMulti = false }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .put(defaultConfig.saveDistrictMatterEndPoint, payload)
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$swal({
                  title: 'Saved successfully',
                  icon: 'success',
                  text: 'Case has been successfully added and orders syncing is in progress.\nPlease check the orders after 30 minutes.',
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: false,
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-danger'
                  },
                  buttonsStyling: false
                })
                if (!isMulti) {
                  ctx.$bvModal.hide('modal-create-matter')
                }
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewMatterLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Update Matter Public Data
    updatePublicData({ commit }, { ctx, matter_id, payload }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(
            defaultConfig.updateMatterPublicDataEndPoint({ id: matter_id }),
            payload
          )
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Data Updated Successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                ctx.$bvModal.hide('modal-create-client')
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeClientLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Update Matter Public Data
    updateTitleData({ commit }, { ctx, matter_id, payload }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(
            defaultConfig.updateMatterTitleEndPoint({ id: matter_id }),
            payload
          )
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Data Updated Successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                ctx.$bvModal.hide('modal-create-client')
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeClientLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    updateUserDateData({ commit }, { ctx, matter_id, payload }) {
      commit('changeNewMatterLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(
            defaultConfig.updateMatterHearingDateEndPoint({ id: matter_id }),
            payload
          )
          .then((response) => {
            commit('changeNewMatterLoading', false)
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Data Updated Successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                ctx.$bvModal.hide('modal-create-client')
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeClientLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Delete Matter
    deleteMatter({ commit, dispatch }, { ctx, _id }) {
      console.log('Matter Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.deleteMatterEndPoint({ id: _id }))
          .then((response) => {
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Matter deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
            return null
          })
      })
    },

    // Find Matters
    refreshMatter({ commit, dispatch }, { ctx, id, unique_id }) {
      console.log('Matter Refresh : ', unique_id)
      commit('changeMatterRefreshLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.findMatterEndPoint({ unique_id }), {
            matter_id: id,
            refresh: true
          })
          .then((response) => {
            commit('changeMatterRefreshLoading', false)
            console.log({
              ReFetchingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                dispatch('fetchMatter', { id })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title:
                      'Court server not responding, Please try after some time!',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    'Court server not responding, Please try after some time!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeMatterRefreshLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title:
                  'Court server not responding, Please try after some time!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Matter Count ------------------------------->>>>>>>>>

    // Get Tracker Count
    fetchTrackerCount(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getTrackerCount({ type: 'case_status' }))
          .then((response) => {
            if (response.data.success) {
              ctx.commit('setTrackerCountData', response.data)
            }
            return resolve(response.data)
          })
          .catch((error) => {
            reject(error)
            return null
          })
      })
    },

    // Get Tracker Count Yearly
    fetchTrackerCountYearly(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getTrackerCount({ type: 'case_year' }))
          .then((response) => {
            if (response.data.success) {
              ctx.commit('setTrackerCountYearlyData', response.data)
            }
            return resolve(response.data)
          })
          .catch((error) => {
            reject(error)
            return null
          })
      })
    },

    // Get Judgments ------------------------------->>>>>>>>>

    fetchMatterJudgment(ctx, matterId) {
      console.log('matter Id --->', matterId)
      ctx.commit('changeJudgmentLoading', true)
      ctx.commit('clearJudgmentData')
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getJudgmentEndPoint + '/' + matterId)
          .then((response) => {
            console.log({
              FetchingJudgment: JSON.stringify(response.data)
            })
            if (response.data.success) {
              ctx.commit('setJudgmentData', response.data)
            }
            ctx.commit('changeJudgmentLoading', false)
          })
          .catch((error) => {
            ctx.commit('changeJudgmentLoading', false)
            reject(error)
          })
      })
    },

    refreshJudgments({ commit }, { ctx, matterId }) {
      commit('changeRefreshJudgmentLoading', true)
      var payload = {
        matter_id: matterId
      }
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.refreshJudgmentEndPoint, payload)
          .then((response) => {
            commit('changeRefreshJudgmentLoading', false)
            console.log({ ExportingJudgment: JSON.stringify(response.data) })
            if (response.data.success) {
              return resolve(response.data)
            }
            return null
          })
          .catch((error) => {
            commit('changeRefreshJudgmentLoading', false)
            reject(error)
            return null
          })
      })
    },

    exportAllJudgments({ commit }, { ctx, id }) {
      commit('changeExportJudgmentLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.allJudgmentExportEndPoint({ id }))
          .then((response) => {
            commit('changeExportJudgmentLoading', false)
            console.log({ ExportingJudgment: JSON.stringify(response.data) })
            if (response.data.success) {
              return resolve(response.data)
            }
            return null
          })
          .catch((error) => {
            commit('changeExportJudgmentLoading', false)
            reject(error)
            return null
          })
      })
    },

    // Matter Notes ------------------------------->>>>>>>>>

    // Get Notes
    getMatterNotes(ctx, matter_id) {
      console.log('Notes Id: ', matter_id)
      ctx.commit('changeNotesLoading', true)
      ctx.commit('clearNotesData')
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getMatterNotesEndPoint({ id: matter_id }))
          .then((response) => {
            if (response.data.success) {
              ctx.commit('setNotesData', response.data)
            }
            ctx.commit('changeNotesLoading', false)
          })
          .catch((error) => {
            reject(error)
            ctx.commit('changeNotesLoading', false)
          })
      })
    },

    // Save Note
    saveNote({ commit, dispatch }, { ctx, payload }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.saveMatterNoteEndPoint, payload)
          .then((response) => {
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Note saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                dispatch('getMatterNotes', payload.matter_id)
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Delete Note
    deleteNote({ commit, dispatch }, { ctx, _id, matter_id }) {
      console.log('Notes Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.deleteMatterNoteEndPoint({ id: _id }))
          .then((response) => {
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Note deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                dispatch('getMatterNotes', matter_id)
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Matter Documents ------------------------------->>>>>>>>>

    // Get Docs
    getMatterDocuments(ctx, matter_id) {
      console.log('Matter Id: ', matter_id)
      ctx.commit('changeDocsLoading', true)
      ctx.commit('clearDocsData')
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getMatterDocsEndPoint({ id: matter_id }))
          .then((response) => {
            if (response.data.success) {
              ctx.commit('setDocsData', response.data)
            }
            ctx.commit('changeDocsLoading', false)
          })
          .catch((error) => {
            reject(error)
            ctx.commit('changeDocsLoading', false)
          })
      })
    },
    // Save Note
    saveDocument({ commit, dispatch }, { ctx, payload, matter_id }) {
      let data = payload
      console.log('Matter --->', matter_id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.saveMatterDocEndPoint, data)
          .then((response) => {
            console.log({
              SavingMatterData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Document saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                dispatch('getMatterDocuments', matter_id)
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },
    // Delete Document
    deleteDocument({ commit, dispatch }, { ctx, _id, matter_id }) {
      console.log('Doc Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.deleteMatterDocEndPoint({ id: _id }))
          .then((response) => {
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Document deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                dispatch('getMatterDocuments', matter_id)
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    exportAllMatter({ commit }, payload) {
      const { ctx, court_id, type, range } = payload
      return new Promise((resolve, reject) => {
        console.log('Runnning')
        axiosIns
          .get(defaultConfig.exportAllMattersEndPoint({ court_id: court_id, type: type, range: range }), {
            responseType: 'blob'
          })
          .then((response) => {
            commit('changeMatterPreviewLoading', false)
            console.log({
              'Export-->': JSON.stringify(response.data)
            })
            var _url = window.URL.createObjectURL(response.data)
            window.open(_url, '_blank').focus() // window.open + focus
            return resolve(response.data)
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    }
  }
}
